// This file is used to catch all routes that are not defined in the pages directory
export default function PageDefault() {
  return (
    <div>
      <div className="mx-auto flex max-w-lg flex-col items-center justify-center py-20">
        <h1 className="text-2xl font-bold">404 - Not Found</h1>
        <p>
          <span className="text-gray-600">This page could not be found.</span>
        </p>
      </div>
    </div>
  );
}
